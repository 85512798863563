<template>
  <div class="point-card-binding-area">
    <div>
      <p class="area-title">綁定點數卡</p>
      <div class="flex items-center gap-[12px] justify-between">
        <div v-show="!selected.length" class="area-content">尚未編輯</div>
        <div v-show="selected.length" class="area-content">{{ displayData }}</div>
        <BaseElButton class="edit-btn" size="small" plain @click="modal.selector = true">編輯</BaseElButton>
      </div>
    </div>
    <hr>
    <div>
      <p class="area-title">扣點費率</p>
      <ElInputWrapper class="w-full">
        <BaseElInput v-model="syncPointCardRate" placeholder="請輸入數字" clearable>
          <template #suffix>
            <span>分鐘</span>
          </template>
        </BaseElInput>
      </ElInputWrapper>
    </div>

    <PointCardAdvanceSelect
      v-if="modal.selector"
      :data="assetsData.pointCard"
      :selectedData="selected"
      multiple
      @confirm="onUpdate"
      @close="modal.selector = false"
    />
  </div>
</template>

<script>
import PointCardAdvanceSelect from '@/components/Select/PointCardAdvanceSelect.vue'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { computed, defineComponent, inject, reactive, ref } from 'vue'
import { useVModel } from '@vueuse/core'
import { map, get, join } from 'lodash'

export default defineComponent({
  name: 'PointCardBindingArea',
  components: {
    ElInputWrapper,
    PointCardAdvanceSelect,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    pointCardRate: {
      type: [Number, String],
      default: 0,
    },
  },
  setup (props, { emit }) {
    const assetsData = inject('assetsData')

    const syncData = useVModel(props, 'selected', emit)
    const syncPointCardRate = useVModel(props, 'pointCardRate', emit)
    const modal = reactive({
      selector: false,
    })

    const onUpdate = (newData) => {
      syncData.value = newData
    }

    const displayData = computed(() => {
      return join(map(props.selected, (id) => {
        return get(assetsData.pointCard.find((item) => item.id === id), 'name')
      }), '、')
    })

    return { modal, onUpdate, assetsData, displayData, syncPointCardRate }
  },
})
</script>

<style scoped lang="postcss">
.point-card-binding-area {
    @apply border border-gray-30 rounded-[12px] p-[20px];
}
.area-title {
    @apply font-medium text-sub;
}
.area-content {
    @apply text-gray-60 text-sub;
}

.edit-btn {
  @apply border border-primary-100 text-sm px-[12px] py-[3.5px];
}
</style>
