import { reactive, computed, ref } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { nanoid } from 'nanoid'
import { forEach, map, includes, compact, toNumber, filter } from 'lodash'
import { GetBranchStore } from '@/api/branchStore'
import { useFetch } from '@/use/fetch'
import { useShop } from './shop'

export const useEditEntryControl = () => {
  const settings = reactive({
    branchApplyMode: 'allBranch', // allBranch┃eachBranch

    allBranchStoreSettings: {
      weekApplyMode: 'allWeek', // allWeek┃eachWeek
      allDaySettings: {},
      eachDaySettings: [],
    },
    eachBrachStoreSettings: [],
  })

  const formRefList = ref([])

  const syncSettingData = (config) => {}

  const initSettings = async () => {
    const { fetchAll } = useFetch()
    const { shopId } = useShop()
    formRefList.value = []

    let branchStores = []

    await fetchAll(GetBranchStore, {
      shopId: shopId.value,
    }, (res) => {
      branchStores = res
    })

    const callList = []

    callList.push(async () => {
      const { formModel, formData, newSettingModel } = useEntryControlSettingModel()
      newSettingModel({})
      settings.allBranchStoreSettings.allDaySettings = formData
      formRefList.value.push({
        id: formData.value.id,
        formModel,
      })
    })

    callList.push(async () => {
      for (let count = 0; count < 7; count++) {
        const { formModel, formData, newSettingModel } = useEntryControlSettingModel()
        newSettingModel({})
        settings.allBranchStoreSettings.eachDaySettings.push({
          enable: true,
          week: count,
          data: formData,
        })
        formRefList.value.push({
          id: formData.value.id,
          formModel,
        })
      }
    })

    callList.push(async () => {
      const subCallList = []
      for (const branchStore of branchStores) {
        settings.eachBrachStoreSettings.push({
          branchStore,
          settings: {
            weekApplyMode: 'allWeek',
            allDaySettings: {},
            eachDaySettings: [],
          },
        })
      }

      subCallList.push(async () => {
        forEach(settings.eachBrachStoreSettings, async (storeSetting) => {
          const { formModel, formData, newSettingModel } = useEntryControlSettingModel()
          newSettingModel({})
          storeSetting.settings.allDaySettings = formData
          storeSetting.settings.enable = true
          formRefList.value.push({
            id: formData.value.id,
            formModel,
          })
        })
      })

      subCallList.push(async () => {
        forEach(settings.eachBrachStoreSettings, async (storeSetting) => {
          for (let count = 0; count < 7; count++) {
            const { formModel, formData, newSettingModel } = useEntryControlSettingModel()
            newSettingModel({})
            storeSetting.settings.eachDaySettings.push({
              enable: true,
              week: count,
              data: formData,
            })
            formRefList.value.push({
              id: formData.value.id,
              formModel,
            })
          }
        })
      })

      await Promise.all(subCallList.map((call) => call()))
    })

    await Promise.all(callList.map((call) => call()))
  }

  return { settings, syncSettingData, initSettings, formRefList }
}

export const useEntryControlSettingModel = () => {
  const formData = ref({
    allDaySettings: {},
    eachDaySettings: [],
  })

  const newSettingModel = ({ id, allPeriodAssets, periodSettings, overtimeExitSetting, maxDurationSetting }) => {
    formData.value = new EntryControlSettingModel({
      id,
      allPeriodAssets,
      periodSettings,
      overtimeExitSetting,
      maxDurationSetting,
    })
  }

  const formRules = computed(() => {
    return {
      allDaySettings: {
        overtimeExitSetting: { required },
        maxDurationSetting: { required },
      },
      eachDaySettings: {
        required,
      },
    }
  })

  const formModel = useVuelidate(formRules, { formData })

  return { formModel, formData, newSettingModel }
}

export class EntryControlSettingModel {
  constructor ({ id, timeApplyMode, allPeriodAssets, periodSettings, overtimeExitSetting, maxDurationSetting, punchCardIds, pointCards }) {
    this.id = id || nanoid(6)
    this.timeApplyMode = timeApplyMode || 'allTime'
    this.allPeriodAssets = allPeriodAssets || {
      accept: [],
      punchCardIds: [],
      pointCardIds: [],
      punchCards: [],
      pointCards: [],
      pointCardRate: undefined,
    }
    this.periodSettings = periodSettings || [
      {
        start: undefined,
        end: undefined,
        assets: {
          accept: [],
          punchCardIds: [],
          pointCardIds: [],
          pointCardRate: undefined,
        },
      },
    ]
    // 超時進場設定
    this.overtimeExitSetting = overtimeExitSetting || {
      enable: false,
      entitlementApplyMode: 'allEntitlement',
      allSetting: {
        rule: undefined,
        maxDuration: undefined,
      },
      eachSetting: [],
    }
    // 但次進場時數上限設定
    this.maxDurationSetting = maxDurationSetting || {
      enable: false,
      entitlementApplyMode: 'allEntitlement',
      allSetting: {
        maxDuration: undefined,
      },
      eachSetting: [],
    }
  }

  addPeriodSetting () {
    this.periodSettings.push({
      start: undefined,
      end: undefined,
      assets: {
        accept: [],
        punchCardIds: [],
        pointCardIds: [],
      },
    })
  }

  removePeriodSetting (index) {
    this.periodSettings.splice(index, 1)
  }

  formatSettingData () {
    return {
      timeApplyMode: this.timeApplyMode,
      allTimeSetting: {
        enablePunchCardEntry: this.allPeriodAssets.accept.includes('punchCard'),
        punchCardIds: this.allPeriodAssets.punchCardIds,
        enablePointCardEntry: this.allPeriodAssets.accept.includes('pointCard'),
        pointCardIds: this.allPeriodAssets.pointCardIds,
        pointCardRate: toNumber(this.allPeriodAssets.pointCardRate) || 0,
      },
      allTimePeriods: map(this.periodSettings, (period) => {
        return { start: period.start, end: period.end }
      }),
      eachTimeSettings: compact(map(this.periodSettings, (period) => {
        return {
          period: {
            start: period.start,
            end: period.end,
          },
          setting: {
            enablePunchCardEntry: includes(period.assets.accept, 'punchCard'),
            punchCardIds: period.assets.punchCardIds,
            enablePointCardEntry: includes(period.assets.accept, 'pointCard'),
            pointCardIds: period.assets.pointCardIds,
            pointCardRate: toNumber(period.assets.pointCardRate) || 0,
          },
        }
      })),

      enableMaxDuration: this.maxDurationSetting.enable,
      maxDurationSetting: {
        entitlementApplyMode: this.maxDurationSetting.entitlementApplyMode,
        allEntitlementSetting: {
          maxDuration: toNumber(this.maxDurationSetting.allSetting.maxDuration) || 0,
        },
        eachEntitlementSettings: map(this.maxDurationSetting.eachSetting, (setting) => {
          return {
            type: setting.type,
            originId: setting.originId,
            enable: setting.enable,
            setting: {
              maxDuration: toNumber(setting.maxDuration),
            },
          }
        }),
      },
      enableOvertimeExit: this.overtimeExitSetting.enable,
      overtimeExitSetting: {
        entitlementApplyMode: this.overtimeExitSetting.entitlementApplyMode,
        allEntitlementSetting: {
          allowRule: this.overtimeExitSetting.allSetting.rule,
          allowMinutes: toNumber(this.overtimeExitSetting.allSetting.maxDuration) || 0,
        },
        eachEntitlementSettings: map(this.overtimeExitSetting.eachSetting, (setting) => {
          return {
            type: setting.type,
            originId: setting.originId,
            enable: setting.enable,
            setting: {
              rule: setting.rule,
              maxDuration: toNumber(setting.maxDuration) || 0,
            },
          }
        }),
      },
    }
  }
}

export const entitlementApplyMode = {
  allEntitlement: 'allEntitlement',
  eachEntitlement: 'eachEntitlement',
}

export const timeApplyMode = {
  allTime: 'allTime',
  eachTime: 'eachTime',
}

export const weekApplyMode = {
  allWeek: 'allWeek',
  eachWeek: 'eachWeek',
}

export const overtimeExitRule = {
  allowMinutes: 'allowMinutes',
  allowForever: 'allowForever',
}
