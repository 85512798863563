<template>
  <BaseDialog
    title="開放超時出場"
    width="610px"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div class="pb-[40px]">
      <p>以下僅有「所有時段開放進場權益」綁定的進場權益</p>

      <BaseElForm ref="formRef" :model="formData" :rules="formRules">
        <div class="max-h-[300px] overflow-y-auto">
          <BaseElFormItem
            v-for="item in formData"
            :key="item.originId"
            testName="次卡"
          >
            <div class="flex flex-col">
              <div class="flex items-center justify-between">
                <p>次卡</p>
                <BaseElCheckbox
                  v-model="item.enable"
                  @change="resetField(`${item.originId}.setting.allowMinutes`)"
                >
                  開放超時出場
                </BaseElCheckbox>
              </div>

              <div class="flex items-center gap-[8px]">
                <ElInputWrapper class="w-full">
                  <BaseElSelect
                    v-model="item.setting.allowRule"
                    :disabled="!item.enable"
                  >
                    <BaseElSelectOption
                      label="允許超時 n 分鐘"
                      value="allowMinutes"
                    />
                    <BaseElSelectOption
                      label="無論超時多久均可出場"
                      value="allowForever"
                    />
                  </BaseElSelect>
                </ElInputWrapper>
                <ElInputWrapper class="w-full">
                  <BaseElFormItem :prop="`${item.originId}.setting.allowMinutes`">
                    <BaseElInput
                      v-model="item.setting.allowMinutes"
                      type="number"
                      placeholder="請輸入數字"
                      :disabled="!item.enable"
                    />
                  </BaseElFormItem>
                </ElInputWrapper>
              </div>
            </div>
          </BaseElFormItem>
        </div>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { computed, defineComponent, inject, onBeforeMount, reactive, ref, set } from 'vue'
import { map, find, forEach, values, toNumber, get } from 'lodash'
import { isDigitRules, noEmptyRules } from '@/validation'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'AssetsOvertimeExitBindingModal',
  components: {
    BaseDialog,
    ElInputWrapper,
  },
  props: {
    activeAssets: {
      type: Object,
      default: () => ({
        punchCardIds: [],
        pointCardIds: [],
      }),
    },
    settings: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const assetsData = inject('assetsData')
    const formRef = ref(null)
    const formData = reactive({})
    const formRules = computed(() => {
      const rules = {}

      forEach(formData, (item, key) => {
        if (item.enable) {
          set(rules, `${key}.setting.allowMinutes`, [noEmptyRules(), isDigitRules()])
        }
      })

      return rules
    })

    const onConfirm = async () => {
      const valid = await formUtils.checkForm(formRef.value)
      if (!valid) return
      emit('confirm', values(formData))
      emit('close')
    }

    const init = async () => {
      const callList = []

      callList.push(async () => {
        forEach(props.activeAssets.punchCardIds, (id) => {
          set(formData, id, {
            type: 'punchCard',
            originId: id,
            name: get(find(assetsData.punchCard, { id }), 'name'),
            enable: false,
            setting: {
              allowRule: 'allowMinutes',
              allowMinutes: undefined,
            },
          })
        })
      })

      callList.push(async () => {
        forEach(props.activeAssets.pointCardIds, (id) => {
          set(formData, id, {
            type: 'pointCard',
            originId: id,
            name: get(find(assetsData.pointCard, { id }), 'name'),
            enable: false,
            setting: {
              allowRule: 'allowMinutes',
              allowMinutes: undefined,
            },
          })
        })
      })

      await Promise.all(callList.map((fn) => fn()))
    }

    const syncFormData = () => {
      const settings = JSON.parse(JSON.stringify(props.settings))
      for (const setting of settings) {
        set(formData, setting.originId, setting)
      }
    }

    const resetField = (field) => {
      formUtils.clearValidate(formRef.value, [field])
    }

    onBeforeMount(async () => {
      init()
      syncFormData()
    })

    return { formData, onConfirm, formRef, formRules, resetField }
  },
})

</script>

  <style scoped lang="postcss">

  </style>
