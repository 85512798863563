<template>
  <main class="edit-entry-control-time-and-price-settings">
    <PageTitle
      title="編輯進場時與收費模式"
      icon="chevron_left"
      hideBtn
      @iconClick="goBack"
    />
    <BasicSetting
      :value.sync="settings.branchApplyMode"
    />

    <div v-show="settings.branchApplyMode === 'allBranch'">
      <AllBranchStoreSettingBlock
        :formData.sync="settings.allBranchStoreSettings"
      />
    </div>

    <div v-show="settings.branchApplyMode === 'eachBranch'" class="flex flex-col gap-[30px]">
      <EachBranchStoreSettingBlock
        v-for="branchSetting in settings.eachBrachStoreSettings"
        :key="branchSetting.id"
        :formData.sync="branchSetting.settings"
        :branchStore="branchSetting.branchStore"
      />
    </div>

    <PageFixedFooter
      @confirm="onSubmit"
      @cancel="onCancel"
    />
  </main>
</template>

<script>
import { computed, defineComponent, onBeforeMount, provide, reactive, ref } from 'vue'
import BasicSetting from './components/BasicSetting.vue'
import AllBranchStoreSettingBlock from './components/AllBranchStoreSettingBlock.vue'
import EachBranchStoreSettingBlock from './components/EachBranchStoreSettingBlock.vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import { useRouter } from 'vue-router/composables'
import { useEditEntryControl } from '@/use/useEditEntryControl'
import { map, toString, some } from 'lodash'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { GetPunchCard } from '@/api/punchCard'
import { GetPointCard } from '@/api/pointCard'
import { UpdateEntryControlConfig, GetEntryControlConfig } from '@/api/entryControl'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'EditEntryControlTimeAndPriceSetting',
  components: {
    PageTitle,
    BasicSetting,
    AllBranchStoreSettingBlock,
    EachBranchStoreSettingBlock,
  },
  setup () {
    const router = useRouter()
    const { fetchAll } = useFetch()
    const { shopId } = useShop()
    const configData = ref({})
    const { settings, initSettings } = useEditEntryControl()
    const formRefList = ref([])

    const assetsData = reactive({
      punchCard: [],
      pointCard: [],
    })

    const submitPayload = computed(() => {
      const data = {
        enable: false,
        entrySetting: {
          branchApplyMode: settings.branchApplyMode,
          allBranchSetting: {
            weekApplyMode: settings.allBranchStoreSettings.weekApplyMode,
            allWeekSetting: settings.allBranchStoreSettings.allDaySettings.formatSettingData(),
            eachWeekSettings: map(settings.allBranchStoreSettings.eachDaySettings, (day) => {
              return {
                enable: day.enable,
                week: toString(day.week),
                setting: day.data.formatSettingData(),
              }
            }),
          },
          eachBranchSetting: map(settings.eachBrachStoreSettings, (branchSetting) => {
            return {
              branchId: branchSetting.branchStore.id,
              enable: branchSetting.enable,
              setting: {
                weekApplyMode: branchSetting.settings.weekApplyMode,
                allWeekSetting: branchSetting.settings.allDaySettings.formatSettingData(),
                eachWeekSettings: map(branchSetting.settings.eachDaySettings, (day) => {
                  return {
                    enable: day.enable,
                    week: toString(day.week),
                    setting: day.data.formatSettingData(),
                  }
                }),
              },
            }
          }),
        },
        entitlementSelectionOrder: configData.value.entitlementSelectionOrder,
      }

      return data
    })
    const goBack = () => {
      router.push({
        name: 'EntryControlSettings',
      })
    }

    const getAssetsData = async () => {
      fetchAll(GetPunchCard, { shopId: shopId.value }, (res) => {
        assetsData.punchCard = res
      })
      fetchAll(GetPointCard, { shopId: shopId.value }, (res) => {
        assetsData.pointCard = res
      })
    }

    const onSubmit = async () => {
      console.log(submitPayload.value)

      const checkFormList = []

      formRefList.value.forEach((formRef) => {
        checkFormList.push(async () => await formUtils.checkForm(formRef.value))
      })

      const validResults = await Promise.all(checkFormList.map((fn) => fn()))
      console.log(validResults)
      if (some(validResults, (valid) => !valid)) {
        window.$message.warning('資料有誤或未填寫完整，請檢查')
        return
      }

      const [res, err] = await UpdateEntryControlConfig({
        shopId: shopId.value,
        ...submitPayload.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
    }

    const getConfigData = async () => {
      const [res, err] = await GetEntryControlConfig({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      configData.value = res
    }

    const onCancel = () => {
      router.push({
        name: 'EntryControlSettings',
      })
    }

    provide('assetsData', assetsData)
    provide('formRefList', formRefList)

    onBeforeMount(() => {
      getConfigData()
      getAssetsData()
      initSettings()
    })
    return { goBack, configData, submitPayload, settings, assetsData, formRefList, onSubmit, onCancel }
  },
})

</script>

<style lang="postcss" scoped>
.edit-entry-control-time-and-price-settings {
  @apply flex flex-col gap-[30px];
}
</style>
