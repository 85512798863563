<template>
  <div class="section-block">
    <SectionTitle title="基本設定" hideBtn />

    <BaseElRadioGroup v-model="syncValue">
      <BaseElRadio label="allBranch">所有門市套用相同設定</BaseElRadio>
      <BaseElRadio label="eachBranch">個別設定門市</BaseElRadio>
    </BaseElRadioGroup>
  </div>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { useVModel } from '@vueuse/core';
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'BasicSetting',
  components: {
    SectionTitle,
  },
  props: {
    value: {
      type: String,
      default: 'allBranch',
    },
  },
  setup (props, {emit}) {
    const syncValue = useVModel(props, 'value', emit)
    return { syncValue }
  },
})

</script>

<style lang="postcss" scoped>
</style>
