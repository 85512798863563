<template>
  <div class="area-block">
    <BaseElForm ref="formRef" label-position="top" :model="syncData" :rules="formRules">
      <slot />

      <BaseElFormItem label="所有時段綁定相同進場權益">
        <BaseElSwitch
          v-model="syncData.timeApplyMode"
          active-text="是"
          inactive-text="否"
          active-value="allTime"
          inactive-value="eachTime"
        />
      </BaseElFormItem>

      <BaseElFormItem v-show="showComputed.allPeriodAssetsSettingBlock" label="所有時段開放進場權益">
        <BaseElCheckboxGroup
          v-model="syncData.allPeriodAssets.accept"
        >
          <BaseElCheckbox label="punchCard">開放次卡進場</BaseElCheckbox>
          <BaseElCheckbox label="pointCard">開放點數卡進場</BaseElCheckbox>
        </BaseElCheckboxGroup>

        <div class="flex flex-col gap-[8px]">
          <PunchCardBindingArea
            v-if="syncData.allPeriodAssets.accept.includes('punchCard')"
            :selected.sync="syncData.allPeriodAssets.punchCardIds"
          />
          <PointCardBindingArea
            v-if="syncData.allPeriodAssets.accept.includes('pointCard')"
            :selected.sync="syncData.allPeriodAssets.pointCardIds"
            :pointCardRate.sync="syncData.allPeriodAssets.pointCardRate"
          />
        </div>
      </BaseElFormItem>

      <div class="relative">
        <BaseElFormItem label="設定開放進場時段" prop="periodSettings">
          <div class="flex flex-col gap-[20px]">
            <!-- 套用所有權益 -->
            <div v-for="(periodSetting, idx) in syncData.periodSettings" :key="`periodSetting-${idx}`">
              <div class="flex items-center gap-[8px]">
                <PeriodRangeSelect
                  class="w-full"
                  :start.sync="periodSetting.start"
                  :end.sync="periodSetting.end"
                  crossDay
                />
                <div class="cursor-pointer" @click="removePeriod(idx)">
                  <SVGIcon icon="common-trash" width="20" height="20" fill="var(--gray-60)" />
                </div>
              </div>
              <!-- 個別權益 -->
              <div v-show="syncData.timeApplyMode === timeApplyMode.eachTime">
                <BaseElCheckboxGroup
                  v-model="periodSetting.assets.accept"
                >
                  <BaseElCheckbox label="punchCard">開放次卡進場</BaseElCheckbox>
                  <BaseElCheckbox label="pointCard">開放點數卡進場</BaseElCheckbox>
                </BaseElCheckboxGroup>

                <div class="flex flex-col gap-[8px]">
                  <PunchCardBindingArea
                    v-if="periodSetting.assets.accept.includes('punchCard')"
                    :selected.sync="periodSetting.assets.punchCardIds"
                  />
                  <PointCardBindingArea
                    v-if="periodSetting.assets.accept.includes('pointCard')"
                    :selected.sync="periodSetting.assets.pointCardIds"
                    :pointCardRate.sync="periodSetting.assets.pointCardRate"
                  />
                </div>
              </div>
            </div>
          </div>
        </BaseElFormItem>

        <BaseElButton type="text" class="absolute right-0 " style="top: -12px" @click="addPeriod">
          <div class="flex items-center gap-[4px]">
            新增時段
            <MaterialIcon :size="24">add_outlined</MaterialIcon>
          </div>
        </BaseElButton>
      </div>

      <!-- 進場最高時數上限設定 -->
      <BaseElFormItem label="限定單次進場最高時數上限">
        <p v-show="showComputed.maxDurationPreReqHint">請先勾選開放進場權益</p>
        <BaseElSwitch
          v-show="showComputed.maxDurationEnableSwitch"
          v-model="syncData.maxDurationSetting.enable"
          active-text="是"
          inactive-text="否"
        />
        <div v-show="showComputed.maxDurationSetting" class="flex flex-col">
          <ElInputWrapper class="w-full">
            <BaseElInput
              v-show="syncData.maxDurationSetting.entitlementApplyMode === entitlementApplyMode.allEntitlement"
              v-model="syncData.maxDurationSetting.allSetting.maxDuration"
              placeholder="請輸入單次進場最高時數上限 ( 數字 )"
              clearable
              type="number"
            >
              <template #suffix>
                <span>分鐘</span>
              </template>
            </BaseElInput>
          </ElInputWrapper>
          <AssetsMaxDurationBindingArea
            v-show="syncData.maxDurationSetting.entitlementApplyMode === entitlementApplyMode.eachEntitlement"
            :settings.sync="syncData.maxDurationSetting.eachSetting"
            :activeAssets="activeAssets"
          />
          <BaseElCheckbox
            v-model="syncData.maxDurationSetting.entitlementApplyMode"
            true-label="allEntitlement"
            false-label="eachEntitlement"
          >
            所有進場權益均有單次進場最高時數上限
          </BaseElCheckbox>
        </div>
      </BaseElFormItem>

      <!-- 超時出場設定 -->
      <BaseElFormItem label="開放超時出場">
        <p v-show="showComputed.overtimeExitPreReqHint">請先勾選開放進場權益</p>
        <BaseElSwitch
          v-show="showComputed.overtimeExitEnableSwitch"
          v-model="syncData.overtimeExitSetting.enable"
          active-text="是"
          inactive-text="否"
        />
        <div v-show="showComputed.overtimeExitSetting">
          <div v-show="syncData.overtimeExitSetting.entitlementApplyMode === entitlementApplyMode.allEntitlement" class="flex items-center gap-[8px]">
            <ElInputWrapper class="w-full">
              <BaseElSelect v-model="syncData.overtimeExitSetting.allSetting.rule">
                <BaseElSelectOption
                  label="允許超時 n 分鐘"
                  value="allowMinutes"
                />
                <BaseElSelectOption
                  label="無論超時多久均可出場"
                  value="allowForever"
                />
              </BaseElSelect>
            </ElInputWrapper>
            <ElInputWrapper v-show="syncData.overtimeExitSetting.allSetting.rule === overtimeExitRule.allowMinutes" class="w-full">
              <BaseElInput
                v-model="syncData.overtimeExitSetting.allSetting.maxDuration"
                placeholder="請輸入數字"
                type="number"
                clearable
              >
                <template #suffix>
                  <span>分鐘</span>
                </template>
              </BaseElInput>
            </ElInputWrapper>
          </div>
          <AssetsOvertimeExitBindingArea
            v-show="syncData.overtimeExitSetting.entitlementApplyMode === entitlementApplyMode.eachEntitlement"
            :settings.sync="syncData.overtimeExitSetting.eachSetting"
            :activeAssets="activeAssets"
          />
          <BaseElCheckbox
            v-model="syncData.overtimeExitSetting.entitlementApplyMode"
            true-label="allEntitlement"
            false-label="eachEntitlement"
          >
            所有進場權益套用相同的超時出場設定
          </BaseElCheckbox>
        </div>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import PeriodRangeSelect from '@/components/PeriodRangeSelect.vue'
import { useVModel } from '@vueuse/core'
import { computed, defineComponent, inject, nextTick, onMounted, ref, watch, watchEffect } from 'vue'
import { entitlementApplyMode, overtimeExitRule, timeApplyMode } from '@/use/useEditEntryControl'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import SVGIcon from '@/components/SVGIcon.vue'
import PunchCardBindingArea from './PunchCardBindingArea.vue'
import PointCardBindingArea from './PointCardBindingArea.vue'
import AssetsMaxDurationBindingArea from './AssetsMaxDurationBindingArea.vue'
import AssetsOvertimeExitBindingArea from './AssetsOvertimeExitBindingArea.vue'
import { uniq, some, get } from 'lodash'

export default defineComponent({
  name: 'EntryControlAssetsAndPeriodSettingsBlock',
  components: {
    PeriodRangeSelect,
    ElInputWrapper,
    SVGIcon,
    PunchCardBindingArea,
    PointCardBindingArea,
    AssetsMaxDurationBindingArea,
    AssetsOvertimeExitBindingArea,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const formRefList = inject('formRefList')
    const formRef = ref(null)
    const formRules = computed(() => {
      const rules = {
        periodSettings: [
          {
            message: '請填寫',
            trigger: ['change', 'blur'],
            validator: (rule, value, callback) => {
              if (some(value, (item) => !item.start || !item.end)) {
                return callback(new Error('請填寫'))
              }
              return callback()
            },
          },
        ],
      }
      return rules
    })
    const syncData = useVModel(props, 'data', emit)

    const addPeriod = () => {
      syncData.value.addPeriodSetting()
    }
    const removePeriod = (idx) => {
      syncData.value.removePeriodSetting(idx)
    }

    const activeAssets = computed(() => {
      if (syncData.value.timeApplyMode === timeApplyMode.allTime) {
        return {
          punchCardIds: syncData.value.allPeriodAssets.punchCardIds,
          pointCardIds: syncData.value.allPeriodAssets.pointCardIds,
        }
      } else if (syncData.value.timeApplyMode === timeApplyMode.eachTime) {
        return {
          punchCardIds: uniq(syncData.value.periodSettings.reduce((acc, item) => {
            return [
              ...acc,
              ...item.assets.punchCardIds,
            ]
          }, [])),
          pointCardIds: uniq(syncData.value.periodSettings.reduce((acc, item) => {
            return [
              ...acc,
              ...item.assets.pointCardIds,
            ]
          }, [])),
        }
      }
      return {
        punchCardIds: [],
        pointCardIds: [],
      }
    })

    const showComputed = computed(() => {
      const show = {
        allPeriodAssetsSettingBlock: syncData.value.timeApplyMode === timeApplyMode.allTime,
        overtimeExitEnableSwitch: false,
        overtimeExitSetting: false,
        overtimeExitPreReqHint: false,
        maxDurationEnableSwitch: false,
        maxDurationSetting: false,
        maxDurationPreReqHint: false,
      }

      // 超時與最高時數設定區塊顯示邏輯
      if (show.allPeriodAssetsSettingBlock) {
        // 如果沒選任何進場權益，顯示提示
        if (get(syncData.value.allPeriodAssets, 'accept.length')) {
          show.maxDurationEnableSwitch = true
          show.overtimeExitEnableSwitch = true

          if (syncData.value.maxDurationSetting.enable) show.maxDurationSetting = true
          if (syncData.value.overtimeExitSetting.enable) show.overtimeExitSetting = true
        } else {
          show.overtimeExitPreReqHint = true
          show.maxDurationPreReqHint = true
          show.overtimeExitSetting = false
          show.maxDurationSetting = false
        }
      } else {
        show.overtimeExitEnableSwitch = true
        show.maxDurationEnableSwitch = true
        if (syncData.value.maxDurationSetting.enable) show.maxDurationSetting = true
        if (syncData.value.overtimeExitSetting.enable) show.overtimeExitSetting = true
      }

      return show
    })

    onMounted(async () => {
      await nextTick()
      formRefList.value.push(formRef)
    })

    return {
      formRef,
      syncData,
      entitlementApplyMode,
      overtimeExitRule,
      timeApplyMode,
      addPeriod,
      removePeriod,
      formRules,
      activeAssets,
      showComputed,
    }
  },
})

</script>

<style scoped lang="postcss">
.area-block {
    @apply bg-[#F6F7FB] p-[20px] rounded-[12px];
}
</style>
