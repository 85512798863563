import { admin2 } from './instance'

export const GetPunchCardConfig = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/punchCardConfig`,
  })
}

export const UpdatePunchCardConfig = async ({
  shopId,
  displayName,
  expireNotifyDays,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/punchCardConfig`,
    data: {
      displayName,
      expireNotifyDays,
    },
  })
}

export const GetPunchCard = async ({
  shopId,
  name,
  start,
  limit,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/punchCard`,
    params: {
      name,
      start,
      limit,
    },
  })
}

export const GetPunchCardCount = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/punchCard/count`,
  })
}

export const FindPunchCard = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/punchCard/${id}`,
  })
}

export const CreatePunchCard = async ({
  shopId,
  name,
  description,
  note,
  order,
  totalAmount,
  expireSetting,
  ImageId,
  image,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/punchCard`,
    data: {
      name,
      description,
      note,
      order,
      totalAmount,
      expireSetting,
      ImageId,
      image,
    },
  })
}

export const UpdatePunchCard = async ({
  shopId,
  id,
  name,
  description,
  note,
  order,
  totalAmount,
  expireSetting,
  ImageId,
  image,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/punchCard/${id}`,
    data: {
      name,
      description,
      note,
      order,
      totalAmount,
      expireSetting,
      ImageId,
      image,
    },
  })
}

export const DeletePunchCard = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'DELETE',
    url: `/${shopId}/punchCard/${id}`,
  })
}

// RECORD

export const GetPunchCardRecord = async ({
  shopId,
  MemberId,
  code,
  name,
  status,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/punchCardRecord`,
    params: {
      MemberId,
      code,
      name,
      status,
    },
  })
}

export const GetPunchCardRecordCount = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/punchCardRecord/count`,
  })
}

export const FindPunchCardRecord = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/punchCardRecord/${id}`,
  })
}

export const CreatePunchCardRecord = async ({
  shopId,
  memberId,
  price,
  note,
  multipleAmount,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/punchCardRecord`,
    data: {
      memberId,
      price,
      note,
      multipleAmount,
    },
  })
}

export const VoidPunchCardRecord = async ({
  shopId,
  punchCardRecordId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/punchCardRecord/adminVoid`,
    data: {
      punchCardRecordId,
    },
  })
}

export const UsePunchCardRecord = async ({
  shopId,
  punchCardRecordId,
  amount,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/punchCardRecord/adminUse`,
    data: {
      punchCardRecordId,
      amount,
    },
  })
}

export const AddPunchCardRecord = async ({
  shopId,
  punchCardRecordId,
  amount,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/punchCardRecord/adminAdd`,
    data: {
      punchCardRecordId,
      amount,
    },
  })
}
